import React, { useState, useEffect } from "react"
import LoadExternalScript from "../utils/load-external-script"
import { markerImageSrc } from "./map-marker-image"
import classNames from "classnames"

/* TODO: To be moved to env. or config. file */
const locRatingScriptsJs =
  "https://www.locrating.com/scripts/locratingIntegrationScripts.js"

const LocRatingMap = props => {
  let [activeCTA] = useState("location")
  const [renderScript, setRenderScript] = useState(false)

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (typeof window.loadLocratingPlugin !== "undefined") {
        renderMap()
      } else {
        setRenderScript(true)
      }
    }
  })

  let _data = props.data || ""

  let _mapClass = "single" // only one marker map
  if (_data.length && _data.length > 1) {
    // more than one markers map
    _mapClass = "all"
  }

  let _markerImage = markerImageSrc

  const _iframeId = props.id

  const _iframeTitle = "Transport Map"

  const renderMap = _activeCTA => {
    if (_activeCTA) {
      activeCTA = _activeCTA
    }

    if (typeof window !== "undefined") {
      const _baseUrl = window.location.origin
      // console.log('_baseUrl : ' + _baseUrl);
      if (_markerImage.toLowerCase().indexOf(_baseUrl) === -1) {
        // if baseUrl not already added ...
        _markerImage = _baseUrl + _markerImage // ... add it
      }
      if (typeof window.loadLocratingPlugin !== "undefined") {
        try {
          let _firstLat = ""
          let _firstLng = ""
          if (_data) {
            if (_data[0]) {
              if (_data[0].lat) {
                _firstLat = _data[0].lat
              }
              if (_data[0].lng) {
                _firstLng = _data[0].lng
              }
            }

            let _firstMarkerImage = _markerImage

            window.loadLocratingPlugin({
              id: _iframeId,
              lat: _firstLat,
              lng: _firstLng,
              type: props.type,
              // hidemenu: true,
              starttab: props.type,
              icon:
                "https://ggfx-locationl.s3.eu-west-2.amazonaws.com/i.prod/marker_d0db45e777.png",
              hidestationswhenzoomedout: true,

              onLoaded: function () {
                let _n = 1
                for (let i in _data) {
                  // console.log('i : ' + i);
                  let _item = _data[i]
                  // console.log('_item (below) : ');
                  // console.log(_item);
                  // console.log('_n : ' + _n);
                  let _markerId = "marker-" + _n
                  // console.log('_markerId : ' + _markerId);
                  let _lat = _item.lat
                  // console.log('_lat : ' + _lat);
                  let _lng = _item.lng
                  // console.log('_lng : ' + _lng);
                  let _html = _item.html

                  if (typeof window.addLocratingMapMarker !== "undefined") {
                    window.addLocratingMapMarker(_iframeId, {
                      id: _markerId,
                      lat: _lat,
                      lng: _lng,
                      html: _html,
                      // icon:
                      //   "https://ggfx-locationl.s3.eu-west-2.amazonaws.com/i.prod/marker_d0db45e777.png",
                      iconHeight: 38,
                      iconWidth: 24,
                    })
                  }
                  _n++
                } // for
                // console.log(typeof window.centerLocratingMapUsingMarkers);
                if (
                  typeof window.centerLocratingMapUsingMarkers !== "undefined"
                ) {
                  // console.log('Inside window.centerLocratingMapUsingMarkers');
                  window.centerLocratingMapUsingMarkers(_iframeId)
                }
              }, // onLoaded: function()
            }) // window.loadLocratingPlugin
          } // if (data)
        } catch (err) {
          console.log(err)
        } // catch (err)
      } // if (typeof window.loadLocratingPlugin !== 'undefined')
    } // if (typeof window !== 'undefined')
  } // const renderMap = ()

  return (
    <div id="map-holder-one" className={classNames("map", _mapClass)}>
      {renderScript && (
        <LoadExternalScript
          src={locRatingScriptsJs}
          // async="true"
          defer="true"
          loadScript={renderMap}
          // appendScriptTo="head"
        />
      )}
      {/* <h1>LocRating Map</h1> */}

      <iframe id={_iframeId} title={_iframeTitle}></iframe>
    </div>
  )
}

export default LocRatingMap
